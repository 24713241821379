import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformModule } from '@angular/cdk/platform';
import { HttpClientModule } from '@angular/common/http';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment as env } from '../environments/environment';
import { TranslocoRootModule } from './transloco-root.module';
import { ToastrModule } from 'ngx-toastr';

let result = '';
const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;
let counter = 0;
while (counter < 10) {
  result += characters.charAt(Math.floor(Math.random() * charactersLength));
  counter += 1;
}

// WS
// const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
//   hostname: env.mqtt.server,
//   port: env.mqtt.port_local,
//   protocol: 'ws',
//   path: '',
//   username: 'mesh',
//   password: 'mesh@12345',
//   clientId: result,
// };

// WSS
const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: "device.meshhome.vn",
  port: 8883,
  protocol: 'wss',
  path: '',
  username: 'meshhome',
  password: 'mesh@12345',
  clientId: result,
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PlatformModule,
    RouterModule,
    HttpClientModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    TranslocoRootModule,
    // ToastrModule.forRoot(), 
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
