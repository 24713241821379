import { ControlComponent } from './page-views/zone/control/control.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'views',
    pathMatch: 'full',
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
    data: { preload: true, delay: false, time: 0 },
  },
  {
    path: 'views',
    loadChildren: () =>
      import('./page-views/page-views.module').then((m) => m.PageViewModule),
    data: { preload: true, delay: false, time: 0 },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
