const SERVER_MAIN = 'https://server.meshhome.vn/';
// const SERVER_MAIN = 'http://192.168.38.60:6868/';
const SERVER_MAIN_UPLOAD = 'https://server.meshhome.vn/';
// const SERVER_MAIN_UPLOAD = 'http://scdn.meshhome.vn:7878/';
const SERVER_MAIN_STREAM = 'ws://118.69.168.44:';
// const SERVER_MAIN_STREAM = 'ws://192.168.38.60:';

const SERVER_MAIN_MESH = 'https://server.meshthings.io/api/';
const SERVER_MUSIC_MESH = 'https://scdn.meshthings.io/api/';

const USER = 'user/';
const ZONE = 'zone/';
const MODEL = 'model/';
const DEVICE = 'device/';
const PORT = 'port/';
const HOUSE = 'home/';
const SCHEDULE = 'schedule/';
const NOTIFICATION = 'notification/';
const SCENE = 'scene/';
const AUTOMATION = 'automation/';
const FLOOR = 'floor/';
const POWER = 'power/';
const PERMISSION = 'permission/';
const ALARM = 'alarm-config/';
const DEVICE_CONFIG = 'config-device/';
const KEY_CONTROL = 'key-control/';
const LOG_HISTORY = 'log-history/';
const STATISTICAL = 'statistical/';

const PUBLICS = 'publics/';
const USERS = 'users/';
const ZONES = 'audio-zones/';
const DEVICES = 'devices/';
const MUSICS = 'musics/';
const PLAYLISTS = 'playlists/';
const AUDIOS = 'audio/';
const KEY_STORE = 'key-store/';

const VERSION = 'v2/';
const VERSION_CONTROL_V3 = 'v3/';
const VERSION_CONTROL = 'v_m/';

export const API = {
  // PROTOCOL_API_SCDN: SERVER_MAIN_UPLOAD,
  PROTOCOL_API_SCDN: SERVER_MAIN,
  PROTOCOL_API_STREAM: SERVER_MAIN_STREAM,
  MESH: {
    LOGOUT: SERVER_MAIN_MESH + USERS + 'logout',
    ACTIVE: SERVER_MAIN_MESH + PUBLICS + 'active',
    LOGIN: SERVER_MAIN_MESH + PUBLICS + 'login',
    SIGNUP: SERVER_MAIN_MESH + PUBLICS + 'signup',
    CHECK_TOKEN: SERVER_MAIN_MESH + USERS + 'check-token',
    GET_DEVICE: SERVER_MAIN_MESH + ZONES + 'get-devices/?zoneId=',
    GET_DEVICE_DS_MD: SERVER_MAIN + DEVICES + 'ds-and-ms',
    GET_ZONE_BY_ID: SERVER_MAIN_MESH + ZONES + '?zoneId=',
    CREATE_ZONE: SERVER_MAIN_MESH + ZONES,
    CREATE_DEVICE_DS: SERVER_MAIN_MESH + USERS + 'active-device',
    INSERT_DEVICE_DS_TO_ROOM: SERVER_MAIN_MESH + ZONES + 'add-device?zoneId=',
    GET_ALL_DEVICE_DS: SERVER_MAIN_MESH + DEVICES + 'ds-and-ms',
    REMOVE_DEVICE_DS: SERVER_MAIN_MESH + USERS + 'remove-device',
    GET_NUMBER_PAGE_MUSIC: SERVER_MAIN_MESH + MUSICS + 'get-page-number',
    GET_ALL_SKIP_MUSIC: SERVER_MAIN_MESH + MUSICS + 'get-by-user-skip',
    ACCEPT_REMOVE_MEDIA: SERVER_MAIN_MESH + MUSICS + 'accept-del-many',
    GET_NUMBER_PAGE_AUDIO_ZONE:
      SERVER_MAIN_MESH + ZONES + 'get-page-number-musics',
    GET_ALL_AUDIO_SKIP_ZONE: SERVER_MAIN_MESH + ZONES + 'get-musics-skip',
    GET_ALL_PLAYLIST_ZONE_SKIP: SERVER_MAIN_MESH + ZONES + 'get-playlists-skip',
    GET_NUMBER_PAGE_PLAYLIST_ZONE:
      SERVER_MAIN_MESH + ZONES + 'get-page-number-playlists',
    GET_MUSIC_PLAYLIST:
      SERVER_MAIN_MESH + PLAYLISTS + 'get-musics/?playlistId=',
    TOPIC_DEVICE_CONNECTED: 'device-connected',
    TOPIC_DEVICE_DISCONNECTED: 'device-disconnected',
    TOPIC_RECEIVE_AUDIO: 'infor-audio',
    TOPIC_SET_VOLUME:
      SERVER_MAIN_MESH + DEVICES + AUDIOS + 'volume-music/?zoneId=',
    GET_ALL_INFO: SERVER_MAIN_MESH + DEVICES + 'audio/get-infor-all?zoneId=',
    PLAY_MUSIC: SERVER_MAIN_MESH + DEVICES + AUDIOS + 'play-music/?zoneId=',
    PLAY_PLAYLIST:
      SERVER_MAIN_MESH + DEVICES + AUDIOS + 'play-playlist/?zoneId=',
  },

  KEY_CONTROL: {
    GET_ALL: SERVER_MAIN + VERSION + KEY_CONTROL + 'all',
  },

  KEY_STORE: {
    CHECK_PASSWORD_DEVICE: SERVER_MAIN + VERSION + KEY_STORE + 'check-password',
    CHANGE_PASSWORD_DEVICE:
      SERVER_MAIN + VERSION + KEY_STORE + 'change-password',
  },

  LOG_HISTORY: {
    GET_LOG_PORT_TIMESTAMP:
      SERVER_MAIN + VERSION + LOG_HISTORY + 'get-logs-timestamp?portId=',
  },

  AUTHENTICATION: {
    LOGIN: SERVER_MAIN + VERSION + USER + 'signIn',
    LINKING_TOKEN: SERVER_MAIN + VERSION + USER + 'linking-account-ds',
    LOG_OUT: SERVER_MAIN + VERSION + USER + 'logOut',
    REGISTER: SERVER_MAIN + VERSION + USER + 'signUp',
    AUTO_REGISTER: SERVER_MAIN + VERSION + USER + 'auto-signUp',
    ACTIVE: SERVER_MAIN + VERSION + USER + 'active',
    CHANGE_NAME: SERVER_MAIN + VERSION + USER + 'change-username',
    CHANGE_PASSWORD: SERVER_MAIN + VERSION + USER + 'change-password',
    CHECK_TOKEN: SERVER_MAIN + VERSION + USER + 'check-token',
    GET_NEW_TOKEN: SERVER_MAIN + VERSION + USER + 'refresh-token',
    GET_INFO: SERVER_MAIN + VERSION + USER,
    UPLOAD_AVATAR: SERVER_MAIN_UPLOAD + VERSION + USER + 'avatar',
    UPLOAD_AVATAR_OPTIMIZE:
      SERVER_MAIN_UPLOAD + VERSION + USER + 'avatar-optimize',
    UPDATE: SERVER_MAIN + VERSION + USER,
    GET_ALL_INFO: SERVER_MAIN + VERSION + USER + 'info_setting',
    REMOVE_AGENT: SERVER_MAIN + VERSION + USER + 'logOut-agent',
    REMOVE_OTHER_AGENT: SERVER_MAIN + VERSION + USER + 'logOut-other-agent',
    SET_LINKING_DEVICE: SERVER_MAIN + VERSION + USER + 'set-linking-device',
    REMOVE_LINKING_DEVICE:
      SERVER_MAIN + VERSION + USER + 'remove-linking-device',
  },

  NOTIFICATION: {
    GET_ALL_IN_ZONE: SERVER_MAIN + VERSION + NOTIFICATION + 'all/?zoneId=',
    GET_ALL_IN_HOUSE: SERVER_MAIN + VERSION + NOTIFICATION + 'by-home/?homeId=',
  },

  FLOOR: {
    GET_BY_ID: SERVER_MAIN + VERSION + FLOOR + 'all/?zoneId=',
    GET_ALL_IN_HOUSE: SERVER_MAIN + VERSION + FLOOR + 'by-home/?homeId=',
    UPDATE_NAME: SERVER_MAIN + VERSION + FLOOR + 'name',
    CREATE: SERVER_MAIN + VERSION + FLOOR,
    REMOVE: SERVER_MAIN + VERSION + FLOOR + '?floorId=',
  },

  ALARM: {
    GET_ALL_BY_HOUSE: SERVER_MAIN + VERSION + ALARM + '?homeId=',
    ENABLE: SERVER_MAIN + VERSION + ALARM + 'enable',
    INSERT: SERVER_MAIN + VERSION + ALARM,
    UPDATE: SERVER_MAIN + VERSION + ALARM,
    DELETE: SERVER_MAIN + VERSION + ALARM + '?alarmId=',
  },

  AUTOMATION: {
    CREATE: SERVER_MAIN + VERSION + AUTOMATION,
    UPDATE: SERVER_MAIN + VERSION + AUTOMATION,
    GET_BY_HOUSE: SERVER_MAIN + VERSION + AUTOMATION + 'by-home?homeId=',
    REMOVE: SERVER_MAIN + VERSION + AUTOMATION + '?automationId=',
    CHANGE_MODE: SERVER_MAIN + VERSION + AUTOMATION + 'change-mode',
  },

  STATISTICAL: {
    GET_STATISTICAL_BY_PORT_DATE_TYPE:
      SERVER_MAIN + VERSION + STATISTICAL + 'get-data-by-port-type-date',
  },

  ZONE: {
    UPLOAD_IMAGE: SERVER_MAIN_UPLOAD + VERSION + ZONE + 'upload-image/?zoneId=',
    UPLOAD_IMAGE_OPTIMIZE:
      SERVER_MAIN_UPLOAD + VERSION + ZONE + 'upload-image-optimize/?zoneId=',
    REMOVE: SERVER_MAIN + VERSION + ZONE + 'remove',
    UPDATE: SERVER_MAIN + VERSION + ZONE,
    GET_ALL: SERVER_MAIN + VERSION + ZONE + 'by-user',
    INSERT: SERVER_MAIN + VERSION + ZONE,
    REMOVE_PORT: SERVER_MAIN + VERSION + PORT + 'remove-port',
    GET_WEATHER: SERVER_MAIN + VERSION + ZONE + 'weather',
    GET_ALL_BY_HOUSE: SERVER_MAIN + VERSION + ZONE + 'by-house?houseId=',
    GET_DETAIL: SERVER_MAIN + VERSION + ZONE,
    GET_MEMBER: SERVER_MAIN + VERSION + ZONE + 'get-member?zoneId=',
    UPDATE_LINKING: SERVER_MAIN + VERSION + ZONE + 'update-linking',
  },

  SCENE: {
    CREATE: SERVER_MAIN + VERSION + SCENE,
    UPDATE: SERVER_MAIN + VERSION + SCENE,
    GET_ALL_IN_HOME: SERVER_MAIN + VERSION + SCENE + '?homeId=',
    GET_ALL_IN_ZONE: SERVER_MAIN + VERSION + SCENE + 'by-home?homeId=',
    REMOVE: SERVER_MAIN + VERSION + SCENE + '?sceneId=',
    ACTIVE: SERVER_MAIN + VERSION + SCENE + 'active?sceneId=',
    // ACTIVE: SERVER_MAIN + VERSION_CONTROL + SCENE + 'active?sceneId=',
  },

  MODEL: {
    GET_ALL: SERVER_MAIN + VERSION + MODEL + 'all',
  },

  PERMISSION: {
    GET_ALL: SERVER_MAIN + VERSION + PERMISSION + '?homeId=',
    INSERT_USER: SERVER_MAIN + VERSION + PERMISSION + 'insert-user',
    REMOVE_USER: SERVER_MAIN + VERSION + PERMISSION + 'remove-user',
    UPDATE_ROLE: SERVER_MAIN + VERSION + PERMISSION + 'update-role',
    SET_ROOM_FOR_MEMBER: SERVER_MAIN + VERSION + PERMISSION + 'set-room-tenant',
    LEAVE_MEMBER_IN_ROOM:
      SERVER_MAIN + VERSION + PERMISSION + 'remove-room-tenant',
  },

  HOUSE: {
    CHANGE_MODE_AUTOMATION:
      SERVER_MAIN + VERSION + HOUSE + 'change-mode-automation',
    GET_DETAIL: SERVER_MAIN + VERSION + HOUSE,
    GET_ALL: SERVER_MAIN + VERSION + HOUSE,
    CREATE: SERVER_MAIN + VERSION + HOUSE,
    UPLOAD_IMAGE: SERVER_MAIN_UPLOAD + VERSION + HOUSE + 'image/',
    UPLOAD_IMAGE_OPTIMIZE:
      SERVER_MAIN_UPLOAD + VERSION + HOUSE + 'image-optimize/?homeId=',
    UPDATE: SERVER_MAIN + VERSION + HOUSE,
    REMOVE: SERVER_MAIN + VERSION + HOUSE,
    GET_ALL_FAVOR: SERVER_MAIN + VERSION + HOUSE + 'get-favor/',
    SET_DEFAULT: SERVER_MAIN + VERSION + HOUSE + 'set-default/?homeId=',
    GET_WEATHER: SERVER_MAIN + 'get-weather?lat=',
    GET_DEFAULT: SERVER_MAIN + VERSION + HOUSE + 'default',
    SET_LINKING_DS: SERVER_MAIN + VERSION + HOUSE + 'set-linking-ds',
    STREAM_CAMERA: SERVER_MAIN + VERSION + HOUSE + 'play-camera',
    STOP_STREAM_CAMERA: SERVER_MAIN + VERSION + HOUSE + 'stop-camera',
  },

  DEVICE: {
    CALL_RESET_GW: SERVER_MAIN + VERSION + DEVICE + 'reset-gw?mac=',
    CALL_GET_INFORMATION:
      SERVER_MAIN + VERSION + DEVICE + 'get-information?mac=',
    CALL_TO_LEARN_IR_CONTROL:
      SERVER_MAIN + VERSION + PORT + 'call-learn-ir-control',
    REMOVE_CONFIG:
      SERVER_MAIN + VERSION + DEVICE_CONFIG + 'delete-config?configDeviceId=',
    GET_LIST_CONFIG:
      SERVER_MAIN + VERSION + DEVICE_CONFIG + 'by-device?deviceId=',
    SAVE_CONFIG: SERVER_MAIN + VERSION + DEVICE_CONFIG + 'save-config',
    LOAD_CONFIG: SERVER_MAIN + VERSION + DEVICE_CONFIG + 'reload-config',
    DETAIL: SERVER_MAIN + VERSION + DEVICE + 'detail?deviceId=',
    HANDLE_CURTAIN:
      SERVER_MAIN + VERSION_CONTROL_V3 + PORT + 'function-curtain',
    HANDLE_DOOR: SERVER_MAIN + VERSION + PORT + 'function-door',
    HANDLE_AC: SERVER_MAIN + VERSION + PORT + 'function-air-conditioner',
    HANDLE_IR: SERVER_MAIN + VERSION + PORT + 'function-ir?homeId=',
    HANDLE_IR_GATEWAY:
      SERVER_MAIN + VERSION + PORT + 'function-ir-gateway?homeId=',
    REMOVE_CONTROLLER: SERVER_MAIN + VERSION + DEVICE + '?deviceId=',
    UPDATE_SCHEDULE: SERVER_MAIN + VERSION + SCHEDULE,
    SCAN_NEW_DEVICE_GATEWAY:
      SERVER_MAIN + VERSION + PORT + 'scan-new-device-gateway?mac=',
    GET_SCHEDULE_BY_HOME: SERVER_MAIN + VERSION + SCHEDULE + 'by-home?homeId=',
    GET_ALL_PORT: SERVER_MAIN + VERSION + PORT + 'in-device?deviceId=',
    UPDATE_TEMP_AIR: SERVER_MAIN + VERSION + PORT + 'change-value',
    GET_ALL: SERVER_MAIN + DEVICE + VERSION + 'by-user',
    CREATE: SERVER_MAIN + VERSION + DEVICE,
    LINKING: SERVER_MAIN + VERSION + DEVICE + 'linking-user',
    UNLINKING: SERVER_MAIN + VERSION + DEVICE + 'unlinking-user',
    ADD_PORT: SERVER_MAIN + VERSION + PORT + 'add-port',
    CHANGE_STATUS: SERVER_MAIN + VERSION_CONTROL_V3 + PORT + 'change-status',
    ACTIVE_ALA_BELL: SERVER_MAIN + VERSION + PORT + 'active-ala-bell',
    CHANGE_STATUS_LIGHT: SERVER_MAIN + VERSION + PORT + 'change-status-light',
    CHANGE_STATUS_MANY_LIGHT:
      SERVER_MAIN + VERSION + PORT + 'change-status-many-light',
    // CHANGE_STATUS_MANY_LIGHT:
    //   SERVER_MAIN + VERSION_CONTROL + PORT + 'change-status-many-light',
    UPDATE_PORT: SERVER_MAIN + VERSION + PORT + 'update',
    DELETE_PORT: SERVER_MAIN + VERSION + PORT + '?portId=',
    DELETE_PORT_IN_GATEWAY: SERVER_MAIN + VERSION + PORT + 'in-gateway?portId=',
    ADD_PORT_DEVICE: SERVER_MAIN + VERSION + PORT + 'new-port',
    ADD_PORT_SENSOR_DEVICE: SERVER_MAIN + VERSION + PORT + 'new-port-sensor',
    REMOVE_PORT_SENSOR_DEVICE:
      SERVER_MAIN + VERSION + PORT + 'remove-port-sensor?portId=',
    DUPLICATE_PORT: SERVER_MAIN + VERSION + PORT + 'duplicate-port',
    UPDATE: SERVER_MAIN + VERSION + DEVICE,
    GET_ALL_BY_HOUSE: SERVER_MAIN + VERSION + DEVICE + 'by-home/?homeId=',
    GET_ALL_DEVICE_POWER_BY_HOUSE:
      SERVER_MAIN + VERSION + DEVICE + 'device-power-by-home/?homeId=',
  },

  POWER: {
    GET_REPORT: SERVER_MAIN + VERSION + POWER + 'report',
    GET_DATA_REPORT_POWER: SERVER_MAIN + VERSION + POWER + 'get-report-energy',
    GET_REPORT_POWER_MONTH: SERVER_MAIN + VERSION + POWER + 'report-month',
    GET_REPORT_POWER_DATE: SERVER_MAIN + VERSION + POWER + 'report-date',
    GET_REPORT_LAST_AND_CURRENT_MONTH:
      SERVER_MAIN + VERSION + POWER + 'report-last-and-current-month',
    GET_REPORT_LAST_AND_CURRENT_DAY:
      SERVER_MAIN + VERSION + POWER + 'report-last-and-current-day',
  },

  SCHEDULE: {
    ACTIVE_SCHEDULE: SERVER_MAIN + VERSION + SCHEDULE + 'enable',
    UPDATE: SERVER_MAIN + VERSION + SCHEDULE,
  },

  PORT: {
    RESTART_TIME_USING_METER:
      SERVER_MAIN + VERSION + PORT + 'reset-time-using-meter?portId=',
    INSERT_PERIPHERALS: SERVER_MAIN + VERSION + PORT + 'insert-peripherals',
    REMOVE_PERIPHERALS: SERVER_MAIN + VERSION + PORT + 'remove-peripherals',
    UPDATE_DEVICE_REMOTE_IR:
      SERVER_MAIN + VERSION + PORT + 'update-device-remote-ir',
    UPDATE_SETTING_CONFIG_BELL:
      SERVER_MAIN + VERSION + PORT + 'update-setting-ala-bell',
    REMOVE_DEVICE_REMOTE_IR:
      SERVER_MAIN + VERSION + PORT + 'remove-device-remote-ir',
    CLONE_DEVICE_REMOTE_IR:
      SERVER_MAIN + VERSION + PORT + 'clone-device-remote-ir',
    CONTROL_DEVICE_IN_IR: SERVER_MAIN + VERSION + PORT + 'control-device-in-ir',
    INSERT_PORT_REMOTE_IR:
      SERVER_MAIN + VERSION + PORT + 'insert_port_remote_ir/?homeId=',
    CHANGE_STATUS_OTHER_PORT:
      SERVER_MAIN + VERSION + PORT + 'change-status-other-port',
    CALL_CHECK_PORT: SERVER_MAIN + VERSION + PORT + 'call-check-port?mac=',
    CONFIG_DIGITAL: SERVER_MAIN + VERSION + PORT + 'update-digital?homeId=',
    UPDATE_VALUE_CURTAIN:
      SERVER_MAIN + VERSION + PORT + 'value-curtain?homeId=',
    UPDATE_PROTOCOL: SERVER_MAIN + VERSION + PORT + 'update-protocol?homeId=',
    GET_ALL_USER_TYPE_PORT:
      SERVER_MAIN + VERSION + PORT + 'in-user-with-type-port?typePort=',
    GET_ALL_IN_USER: SERVER_MAIN + VERSION + PORT + 'in-user',
    DIM_LIGHT: SERVER_MAIN + VERSION + PORT + 'dim_light',
    DIM_STEP: SERVER_MAIN + VERSION + PORT + 'dim_step',
    GET_ALL_IN_HOME: SERVER_MAIN + VERSION + PORT + 'in-home?homeId=',
    GET_ALL_ZONE:
      SERVER_MAIN + VERSION + PORT + 'in-zone-with-type-port?zoneId=',
    GET_ALL_IN_ZONE_WITHOUT_TYPE:
      SERVER_MAIN + VERSION + PORT + 'in-zone?zoneId=',
    SET_SCHEDULE: SERVER_MAIN + VERSION + SCHEDULE,
    GET_SCHEDULE: SERVER_MAIN + VERSION + SCHEDULE + 'by-port?portId=',
    REMOVE_SCHEDULE: SERVER_MAIN + VERSION + SCHEDULE + '/?scheduleId=',
    SET_FAVOR: SERVER_MAIN + VERSION + PORT + 'set-favor',
    REMOVE_FAVOR: SERVER_MAIN + VERSION + PORT + 'remove-favor',
    UPDATE_LOGO: SERVER_MAIN + VERSION + PORT + 'logo',
    CURTAIN_DELAY: SERVER_MAIN + VERSION + PORT + 'curtain-delay',
    SYNC_PORT_FROM_GATEWAY: SERVER_MAIN + VERSION + PORT + 'sync-form-gateway',
    GET_ALL_IN_HOME_TYPE_PORT:
      SERVER_MAIN + VERSION + PORT + 'in-home-with-type-port?homeId=',
  },
};
