import { API } from './../utils/IIPConfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  loginAccount(form_data): Observable<any> {
    return this.http.post(API.AUTHENTICATION.LOGIN, form_data).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  registerAccount(form_data): Observable<any> {
    return this.http.post(API.AUTHENTICATION.REGISTER, form_data).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  activeAccount(form_data): Observable<any> {
    return this.http.post(API.AUTHENTICATION.ACTIVE, form_data).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  checkToken(): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.CHECK_TOKEN, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getDataOverviewTableDashboard(): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.GET_DATA_OVERVIEW_TABLE_DASHBOARD, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getDataNotificationDashboard(skip): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.GET_DATA_NOTIFICATION_OVERVIEW_DASHBOARD + skip, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getDataAlterDashboard(skip): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.GET_DATA_ALTER_OVERVIEW_DASHBOARD + skip, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getTotalStatisticalDashboard(): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.GET_TOTAL_STATISTICAL_DASHBOARD, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  logOutAccount(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.LOG_OUT, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getAllInfoUser(): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.GET_ALL_INFO, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  removeAgent(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.REMOVE_AGENT, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  logOutAllOtherAgent(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.REMOVE_OTHER_AGENT, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getNewToken(rfToken): Observable<any> {
    return this.http
      .post(
        API.AUTHENTICATION.GET_NEW_TOKEN,
        { rfToken: rfToken },
        {
          headers: this.addHeaderToken(),
        }
      )
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  changePassword(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.CHANGE_PASSWORD, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  updateInformation(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.UPDATE, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  getInfoUser(): Observable<any> {
    return this.http
      .get(API.AUTHENTICATION.GET_INFO, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  setLinkingDevice(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.SET_LINKING_DEVICE, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  removeLinkingDevice(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.REMOVE_LINKING_DEVICE, form_data, {
        headers: this.addHeaderToken(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  uploadImage(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.UPLOAD_AVATAR, form_data, {
        headers: this.addHeaderTokenUpload(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  uploadAvatarOptimize(form_data): Observable<any> {
    return this.http
      .put(API.AUTHENTICATION.UPLOAD_AVATAR_OPTIMIZE, form_data, {
        headers: this.addHeaderTokenUpload(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  updateTokenLinking(form_data): Observable<any> {
    return this.http
      .post(API.AUTHENTICATION.LINKING_TOKEN, form_data, {
        headers: this.addHeaderTokenUpload(),
      })
      .pipe(
        catchError((err) => {
          return of(err);
        })
      );
  }

  addHeaderToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': '*',
      Authorization: 'Bearer ' + localStorage.getItem('tokenSM'),
    });
    return headers;
  }

  addHeaderTokenUpload() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('tokenSM'),
    });
    return headers;
  }
}
