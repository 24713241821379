// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  http: {
    apiUrl: '<https://api.myweb.com>',
  },
  mqtt: {
    server: '139.59.115.246',
    protocol: 'ws',
    port: 1883,
    port_local: 9001,

    // port_local: 8883,
    // server: 'device.meshhome.vn',
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
