import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'mesh-siemens-clent';

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private authenService: AuthenticationService
  ) {}

  ngOnInit() {
    this.checkLanguage();
    this.getCurrentLocation();
  }

  async getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const lat = pos.coords.latitude;
          const long = pos.coords.longitude;
          localStorage.setItem('latMH', lat.toString());
          localStorage.setItem('longMH', long.toString());
        },
        (err) => {
          console.log('Error current position', err);
        }
      );
    }
  }

  async checkToken() {
    const token = await localStorage.getItem('tokenSM');

    if (!token) this.router.navigate(['./authentication/login']);

    if (!!token) {
      this.authenService.checkToken().subscribe((result) => {
        if (result['error']) this.router.navigate(['./authentication/login']);
        else this.getUserByToken();
      });
    }
  }

  getUserByToken() {
    this.authenService.getInfoUser().subscribe((result) => {
      if (result.result === null) {
        return this.router.navigate(['./authentication/login']);
      }
      return this.router.navigate(['./views']);
    });
  }

  checkLanguage() {
    const language = localStorage.getItem('langSM');
    if (!!language) this.translocoService.setActiveLang(language);
    else {
      localStorage.setItem('langSM', 'vn');
      this.translocoService.setActiveLang('vn');
    }
  }
}
